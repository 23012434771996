<template>
  <component :is="userData ? 'b-card' : 'div'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <validation-observer
      v-if="userData"
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(updateUser)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <users-form
          :user-data="userData"
          @update-avatar="updateAvatar"
          @remove-avatar="removeAvatar"
        />

      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BCard, BAlert, BLink, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import UsersForm from '../users-form/UsersForm.vue'
import useUsersForm from '../users-form/useUsersForm'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BForm,
    BButton,

    // Local Component
    UsersForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },

  setup() {
    const {
      userData,

      updateAvatar,
      removeAvatar,
      updateUser,
      fetchUser,
    } = useUsersForm()

    fetchUser()

    return {
      //  Data
      userData,

      //  Actions
      updateAvatar,
      removeAvatar,
      fetchUser,
      updateUser,
    }
  },
}
</script>
